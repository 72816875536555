import React, { useState } from 'react'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { Controller } from 'react-hook-form'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { Input } from '@platform-ui/components/Input'
import { observer } from 'mobx-react-lite'
import { ShowIcon } from '@platform-ui/icons/ShowIcon'
import { HideIcon } from '@platform-ui/icons/HideIcon'
import {
  ButtonStyled,
  ButtonSubmit,
  ContainerCustom,
  Desc,
  Form,
  IconButtonStyled,
  Title,
} from './styled'
import { Hint } from './Hint'
import { useFormState } from './useFormState'
import { Error } from '../Error'

export const PasswordCreate = observer(() => {
  const [showPassword, setShowPassword] = useState(false)
  const {
    form,
    handleValidSubmit,
    hasNumber,
    hasDifferentCaseLetters,
    isCorrectLength,
    secondaryButtonClick,
    passwordSetMutation,
    setSignInScreen,
  } = useFormState()

  const buttonText =
    passwordSetMutation.isError && passwordSetMutation.error.status === 500
      ? 'Попробовать снова'
      : 'Продолжить'

  if (passwordSetMutation.isError && passwordSetMutation.error.status === 403) {
    return <Error statusCode={passwordSetMutation.error.status} buttonClick={setSignInScreen} />
  }

  return (
    <ContainerCustom flexSm>
      <Row flexSm>
        <Col displaySm="flex" justifyContentSm="center" alignItemsSm="center">
          <Form onSubmit={form.handleSubmit(handleValidSubmit)}>
            <div>
              <Title>Придумайте пароль</Title>
              <Desc>
                Пароль будет использоваться для входа в личный кабинет. Не сообщайте его никому
              </Desc>

              <Controller
                name="password"
                control={form.control}
                render={({ field, fieldState }) => (
                  <>
                    <FieldControl isBlock error={fieldState.error?.message}>
                      <Input
                        placeholder="Пароль"
                        type={showPassword ? 'text' : 'password'}
                        isDisabled={passwordSetMutation.isLoading}
                        isBlock
                        isAutoFocus
                        status={fieldState.error?.message ? 'error' : 'default'}
                        rightSlot={
                          field.value && (
                            <IconButtonStyled onClick={() => setShowPassword(!showPassword)}>
                              {showPassword ? <ShowIcon /> : <HideIcon />}
                            </IconButtonStyled>
                          )
                        }
                        {...field}
                      />
                    </FieldControl>

                    <Hint
                      text="Минимум 8 символов"
                      isSuccess={isCorrectLength}
                      isError={fieldState.error?.message}
                    />
                    <Hint
                      text="Минимум 1 цифра"
                      isSuccess={hasNumber}
                      isError={fieldState.error?.message}
                    />
                    <Hint
                      text="Минимум 1 заглавная буква и 1 строчная буква"
                      isSuccess={hasDifferentCaseLetters}
                      isError={fieldState.error?.message}
                    />
                  </>
                )}
              />
            </div>

            <div>
              <ButtonSubmit
                isFullWidth
                type="submit"
                isLoading={passwordSetMutation.isLoading}
                isDisabled={!(hasNumber && hasDifferentCaseLetters && isCorrectLength)}
              >
                {buttonText}
              </ButtonSubmit>

              <ButtonStyled
                isFullWidth
                view="secondary"
                isDisabled={passwordSetMutation.isLoading}
                onClick={secondaryButtonClick}
              >
                В начало
              </ButtonStyled>
            </div>
          </Form>
        </Col>
      </Row>
    </ContainerCustom>
  )
})
