import React from 'react'
import { CheckIcon } from '@platform-ui/icons/CheckIcon'
import { Dot, HintText, HintWrapper } from './styled'

export const Hint = ({ text, isSuccess, isError }) => {
  return (
    <HintWrapper>
      {isSuccess ? (
        <CheckIcon size="s12" color="icons/colors/success" />
      ) : (
        <Dot isError={isError} />
      )}

      <HintText isError={isError} isSuccess={isSuccess}>
        {text}
      </HintText>
    </HintWrapper>
  )
}
