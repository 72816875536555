import { useState } from 'react'
import { validationSuite } from './validationForm'
import { SubmitHandler, useForm } from 'react-hook-form'
import { vestResolver } from '@hookform/resolvers/vest'
import { DataModel, useDataMutation } from '@/api/auth/data'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { toCurrentFormatPhone } from '@/utils/toCurrentFormatPhone'
import { toCorrectBirthDate } from '@/utils/toCorrectBirthDate'
import { CARD_NUMBER, CONTRACT_NUMBER, PASSPORT } from './constants'
import { authorizationStorage } from '@/storage/authorization'
import { ResponseType } from '@/models/ResponseType'

export type FormValues = Readonly<
  PassportFormValues | ContractNumberFormValues | CardNumberFormValues
>

export type PassportFormValues = {
  type: 'passport'
  passport: string
}

export type ContractNumberFormValues = {
  type: 'contractNumber'
  contractNumber: string
}

export type CardNumberFormValues = {
  type: 'cardNumber'
  cardNumber: string
}

export const useFormState = () => {
  const [step, setStep] = useState(1)

  const form = useForm<FormValues>({
    defaultValues: {
      type: PASSPORT,
      passport: '',
    },
    resolver: vestResolver(validationSuite),
    mode: 'onBlur',
  })
  const { watch, setValue } = form
  const { maskedPhoneNumber, birthday } = authorizationStorage.getState()
  const context = useAppContext<AppContext>()
  const formType = watch('type')
  const passportValue = watch(PASSPORT)
  const contractNumberValue = watch(CONTRACT_NUMBER)
  const cardNumberValue = watch(CARD_NUMBER)

  const dataMutation = useDataMutation()

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    if (step === 1) {
      setValue('type', CONTRACT_NUMBER)
      setStep(2)

      return
    }

    let dataResponse: DataModel.Response

    const { passport } = formValues as PassportFormValues
    const { contractNumber } = formValues as ContractNumberFormValues
    const { cardNumber } = formValues as CardNumberFormValues

    const trimPhone =
      maskedPhoneNumber.length > 10
        ? toCurrentFormatPhone(maskedPhoneNumber).slice(1)
        : maskedPhoneNumber

    const trimPassport = passport.replaceAll(' ', '')

    try {
      dataResponse = await dataMutation.mutateAsync({
        clientId: context.auth.clientId,
        birthDate: toCorrectBirthDate(birthday),
        phone: trimPhone,
        passport: trimPassport,
        contract: formType === CONTRACT_NUMBER ? contractNumber : '',
        card: formType === CARD_NUMBER ? cardNumber.replaceAll(' ', '') : '',
        deviceName: context.config.device.platform,
        apiVersion: context.config.apiVersion,
      })
    } catch (error) {
      const err: DataModel.ErrorResponse = error

      if (err.status >= 500) {
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }

      return
    }

    if (dataResponse?.data?.type === ResponseType.ResolveWithData) {
      /**
       * Перенаправление на подтверждение кода
       */
      context.auth.setCreatePassword(true)
      context.auth.setCurrentScreen(context.auth.screens.SMSCode)
    }
  }

  const setSignInScreen = () => {
    /**
     * Перенаправление на стартовую страницу
     */
    context.auth.setCurrentScreen(context.auth.screens.SignIn)
  }

  const isDisabledButton =
    !passportValue ||
    passportValue.trim().length < 12 ||
    (formType === CONTRACT_NUMBER && !contractNumberValue) ||
    (formType === CARD_NUMBER && (!cardNumberValue || cardNumberValue.trim().length < 19))

  return {
    form,
    handleValidSubmit,
    step,
    formType,
    isDisabledButton,
    dataMutation,
    setSignInScreen,
  }
}
