import React from 'react'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { Controller } from 'react-hook-form'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { InputMask } from '@platform-ui/components/InputMask'
import { ContainerCustom, Form, Title, Desc, ButtonSubmit, ButtonStyled } from './styled'
import { useFormState } from './useFormState'
import { Tabs } from './Tabs'
import { PASSPORT, CARD_NUMBER, CONTRACT_NUMBER } from './constants'
import { Error } from '../Error'

export type PassportProps = {}

export const Passport: React.FC<PassportProps> = () => {
  const {
    form,
    handleValidSubmit,
    step,
    formType,
    isDisabledButton,
    dataMutation,
    setSignInScreen,
  } = useFormState()

  const buttonText =
    dataMutation.isError && dataMutation.error.status === 500 ? 'Попробовать снова' : 'Продолжить'

  if (
    dataMutation.isError &&
    (dataMutation.error.status === 400 || dataMutation.error.status === 403)
  ) {
    return <Error statusCode={dataMutation.error.status} buttonClick={setSignInScreen} />
  }

  const tabsFormType = formType === CARD_NUMBER || formType === CONTRACT_NUMBER ? formType : null

  return (
    <ContainerCustom flexSm>
      <Row flexSm>
        <Col displaySm="flex" justifyContentSm="center" alignItemsSm="center">
          <Form onSubmit={form.handleSubmit(handleValidSubmit)}>
            <div>
              <Title>Дополнительные данные</Title>
              <Desc>Шаг {step} из 2</Desc>

              {step === 1 && (
                <Controller
                  name={PASSPORT}
                  control={form.control}
                  render={({ field, fieldState }) => (
                    <FieldControl isBlock error={fieldState.error?.message}>
                      <InputMask
                        placeholder="Серия и номер паспорта"
                        isBlock
                        isDisabled={dataMutation.isLoading}
                        format="## ## ######"
                        type="text"
                        isAutoFocus
                        status={fieldState.error?.message ? 'error' : 'default'}
                        onClear={() => {
                          form.setValue(field.name, '')
                        }}
                        {...field}
                      />
                    </FieldControl>
                  )}
                />
              )}

              {step === 2 && (
                <Tabs
                  formType={tabsFormType}
                  inputDisabled={dataMutation.isLoading}
                  control={form.control}
                  setValue={form.setValue}
                />
              )}
            </div>

            <div>
              <ButtonSubmit
                isFullWidth
                type="submit"
                isDisabled={isDisabledButton}
                isLoading={dataMutation.isLoading}
              >
                {buttonText}
              </ButtonSubmit>
              <ButtonStyled
                isFullWidth
                view="secondary"
                isDisabled={dataMutation.isLoading}
                onClick={setSignInScreen}
              >
                В начало
              </ButtonStyled>
            </div>
          </Form>
        </Col>
      </Row>
    </ContainerCustom>
  )
}
