import { useMutation, MutateOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { PasswordSetModel } from './PasswordSetModel'

export const passwordSet: PasswordSetModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.auth.passwordSet,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.post<
    PasswordSetModel.ResponseData,
    void,
    PasswordSetModel.RequestData,
    PasswordSetModel.RequestHeaders,
    PasswordSetModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      password: props.password,
    },
    responseAdapter: adapter,
    control,
  })
}

export function usePasswordSetMutation(
  options?: MutateOptions<
    PasswordSetModel.Response,
    PasswordSetModel.ErrorResponse,
    PasswordSetModel.Props
  >
) {
  return useMutation(passwordSet, options)
}
