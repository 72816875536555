import styled from '@emotion/styled'
import { Container } from '@platform-ui/components/Container'
import { Headline28, Body16 } from '@platform-ui/typography'
import { Button } from '@platform-ui/components/Button'
import { Tabs } from '@platform-ui/components/Tabs'

export const ContainerCustom = styled(Container)(() => ({
  paddingTop: '24px',
  paddingBottom: '24px',
}))

export const Form = styled.form(({ theme }) => ({
  maxWidth: 368,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const Title = styled(Headline28)(() => ({
  textAlign: 'center',
  marginBottom: 12,
}))

export const Desc = styled(Body16)(() => ({
  textAlign: 'center',
  marginBottom: 24,
}))

export const ButtonSubmit = styled(Button)(() => ({
  marginTop: 128,
}))

export const ButtonStyled = styled(Button)(() => ({
  marginTop: '12px',
}))

export const TabsStyled = styled(Tabs)(() => ({
  marginBottom: 24,
}))
