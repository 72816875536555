import { useMutation, MutateOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { DataModel } from './DataModel'

export const data: DataModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.auth.data,
    {} as ApiManagerModel.ApiOption
  )

  const otherData =
    (props.card && {
      card: props.card,
    }) ||
    (props.contract && {
      contract: props.contract,
    })

  return await HttpClient.post<
    DataModel.ResponseData,
    void,
    DataModel.RequestData,
    DataModel.RequestHeaders,
    DataModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      dfp: props.clientId,
      birth: props.birthDate,
      phone: props.phone,
      passport: props.passport,
      osType: 4, // always 4 for web
      deviceName: props.deviceName,
      ...otherData,
    },
    responseAdapter: adapter,
    control,
  })
}

export function useDataMutation(
  options?: MutateOptions<DataModel.Response, DataModel.ErrorResponse, DataModel.Props>
) {
  return useMutation(data, options)
}
