import { FormValues } from './models'
import { test, create } from 'vest'
import { formatDate } from '@/utils/formatDate'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { isRangeDates } from './utils'
import { toCurrentFormatPhone } from '@/utils/toCurrentFormatPhone'

const oldYear = new Date(new Date().getFullYear() - 100, 0, 0)

export const validationSuite = create((data: FormValues) => {
  test('birthday', () => {
    if (!data.birthday || data.birthday.length === 0) {
      return Promise.reject('Обязательное поле')
    }

    if (!isRangeDates(data.birthday, oldYear, new Date())) {
      return Promise.reject(
        `Дата рождения разрешена в промежутке от ${formatDate(
          oldYear,
          'DD.MM.YYYY'
        )} до ${formatDate(new Date(), 'DD.MM.YYYY')}`
      )
    }
  })

  test('phoneNumber', () => {
    if (!data.phoneNumber || data.phoneNumber.length === 0) {
      return Promise.reject('Обязательное поле')
    }

    if (!isMobilePhone.default(toCurrentFormatPhone(data.phoneNumber), 'ru-RU')) {
      return Promise.reject('Неправильный номер телефона')
    }
  })
})
