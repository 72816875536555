import React, { useEffect } from 'react'
import { HelloLayout } from '../../layouts/hello'
import { Col } from '@platform-ui/components/Col'
import { SignInForm } from '../SignInForm'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Rules } from '@/models/Rules'
import { ContainerCustom, RowCustom, HomeCreditBankLogoStyled } from './styled'
import { authorizationStorage } from '@/storage/authorization'

export type TSignInProps = {}

const resetStorage = (context, hasAuthPasswordAccessRule) => {
  if (hasAuthPasswordAccessRule) {
    context.auth.signOut()
  } else {
    authorizationStorage.reset()
  }
}

const useLoadEvent = (context, hasAuthPasswordAccessRule) =>
  useEffect(() => {
    if (document.readyState === 'complete') {
      resetStorage(context, hasAuthPasswordAccessRule)
    } else {
      typeof window !== 'undefined' &&
        window.addEventListener('DOMContentLoaded', () => {
          resetStorage(context, hasAuthPasswordAccessRule)
        })
    }
  }, [context, hasAuthPasswordAccessRule])

export const SignIn: React.FC<TSignInProps> = () => {
  const context = useAppContext<AppContext>()
  const hasAuthPasswordAccessRule = context.auth.rules.has(Rules.AuthPasswordAccess)
  useLoadEvent(context, hasAuthPasswordAccessRule)

  return (
    <HelloLayout>
      <ContainerCustom flexSm justifyContentSm="space-between" justifyContentMd="space-between">
        <RowCustom>
          <Col offsetMd={3} widthMd={6} widthLg={8} offsetLg={2}>
            <HomeCreditBankLogoStyled />
          </Col>
        </RowCustom>
        <RowCustom flexSm>
          <Col offsetMd={3} widthMd={6} widthLg={8} offsetLg={2}>
            <SignInForm />
          </Col>
        </RowCustom>
      </ContainerCustom>
    </HelloLayout>
  )
}

export default SignIn
