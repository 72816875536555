import React from 'react'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import {
  ContainerCustom,
  Wrapper,
  TitleStyled,
  DescStyled,
  EmojiStyled,
  ButtonStyled,
} from './styled'

export type ErrorProps = {
  buttonClick: () => void
  statusCode: 400 | 403
}

export const Error = ({ buttonClick, statusCode }: ErrorProps) => {
  const title =
    (statusCode === 400 && 'Какие-то данные были введены неверно') ||
    (statusCode === 403 && (
      <>
        Доступ
        <br />
        временно заблокирован
      </>
    ))

  const desc =
    (statusCode === 400 && 'Попробуйте снова') ||
    (statusCode === 403 && 'Повторите попытку входа через час')

  return (
    <ContainerCustom flexSm>
      <Row flexSm>
        <Col
          displaySm="flex"
          justifyContentSm="center"
          alignItemsSm="center"
          flexDirectionSm="column"
        >
          <Wrapper>
            <div>
              <EmojiStyled>😕</EmojiStyled>
              <TitleStyled>{title}</TitleStyled>
              <DescStyled>{desc}</DescStyled>
            </div>

            <ButtonStyled isFullWidth onClick={buttonClick}>
              В начало
            </ButtonStyled>
          </Wrapper>
        </Col>
      </Row>
    </ContainerCustom>
  )
}
