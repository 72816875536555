import { formatDate } from '@/utils/formatDate'
/**
 *
 * @param value
 * @returns "1989-02-28"
 */
const toCurrentFormatDate = (value: Date) => {
  return formatDate(value, 'YYYY-MM-DD')
}

export const birthdayToDate = (value: string) => {
  const [day, month, yaer] = value.split('.').map((item) => Number(item))
  return new Date(yaer, month - 1, day)
}

export function isRangeDates(received: string, floor: Date, ceiling: Date) {
  const [day, month, yaer] = received.split('.').map((item) => {
    const number = Number(item)
    if (isNaN(number)) {
      return 0
    }
    return number
  })

  const date = new Date(yaer, month - 1, day)
  return date.getTime() >= floor.getTime() && date.getTime() <= ceiling.getTime()
}
