import React from 'react'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { InputPhoneNumber } from '@platform-ui/components/InputPhoneNumber'
import { InputDate } from '@platform-ui/components/InputDate'
import { Headline16 } from '@platform-ui/typography'
import { Controller } from 'react-hook-form'
import {
  ButtonCustom,
  CheckboxButtonCustom,
  FieldControlCustom,
  FormContainer,
  Title,
  Form,
} from './styled'
import { useFormState } from './useFormState'
import { observer } from 'mobx-react-lite'

export type SignInFormProps = Readonly<{}>

export const SignInForm = observer<SignInFormProps>(() => {
  const { form, handleValidSubmit, isLoadingSubmitForm } = useFormState()

  return (
    <Form onSubmit={form.handleSubmit(handleValidSubmit)}>
      <FormContainer flexSm>
        <Row flexSm>
          <Col>
            <Headline16>Добро пожаловать</Headline16>
            <Title as="h1">Войти или стать клиентом</Title>

            <Controller
              name="phoneNumber"
              render={({ field, fieldState }) => (
                <FieldControlCustom isBlock error={fieldState.error?.message}>
                  <InputPhoneNumber
                    placeholder="Номер телефона"
                    isBlock
                    isAutoFocus
                    status={fieldState.error?.message ? 'error' : 'default'}
                    onClear={() => {
                      form.setValue(field.name, '')
                    }}
                    {...field}
                  />
                </FieldControlCustom>
              )}
              control={form.control}
            />

            <Controller
              name="birthday"
              render={({ field, fieldState }) => (
                <FieldControlCustom isBlock error={fieldState.error?.message}>
                  <InputDate
                    placeholder="Дата рождения"
                    isBlock
                    status={fieldState.error?.message ? 'error' : 'default'}
                    onClear={() => {
                      form.setValue(field.name, '')
                    }}
                    {...field}
                  />
                </FieldControlCustom>
              )}
              control={form.control}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <ButtonCustom isLoading={isLoadingSubmitForm} type="submit" isFullWidth>
              Войти
            </ButtonCustom>
            <CheckboxButtonCustom {...form.register('disableRememberMe')} isFullWidth>
              Чужой компьютер
            </CheckboxButtonCustom>
          </Col>
        </Row>
      </FormContainer>
    </Form>
  )
})
