import {
  FormValues,
  PassportFormValues,
  ContractNumberFormValues,
  CardNumberFormValues,
} from './useFormState'
import { test, create, group, only, optional } from 'vest'

export const validationSuite = create((data: FormValues) => {
  only.group(data.type === 'passport' ? 'group_passport' : 'group_requisites')
  group('group_passport', () => {
    test('passport', 'Обязательное поле', () => {
      const newValues = data as PassportFormValues
      if (!newValues.passport || newValues.passport.length === 0) {
        return Promise.reject('Обязательное поле')
      }

      /**
       * 10 цифр и 2 пробела = 12 символов
       */
      if (newValues.passport && newValues.passport.trim().length < 12) {
        return Promise.reject('Серия и номер паспорта должны состоять из 10-ти цифр')
      }
    })
  })
  group('group_requisites', () => {
    optional({
      contractNumber: () => validationSuite.get().isValid('cardNumber'),
      cardNumber: () => validationSuite.get().isValid('contractNumber'),
    })

    test('contractNumber', 'Обязательное поле', () => {
      const newValues = data as ContractNumberFormValues

      if (!newValues.contractNumber || newValues.contractNumber.length === 0) {
        return Promise.reject('Обязательное поле')
      }
    })
    test('cardNumber', 'Обязательное поле', () => {
      const newValues = data as CardNumberFormValues

      if (!newValues.cardNumber || newValues.cardNumber.length === 0) {
        return Promise.reject('Обязательное поле')
      }

      /**
       * 16 цифр и 3-и пробела = 19 символов
       */
      if (newValues.cardNumber.trim().length !== 19) {
        return Promise.reject('Номер карты должен состоять из 16-ти цифр')
      }
    })
  })
})
