import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { action, IReactionDisposer, makeAutoObservable, reaction, runInAction } from 'mobx'
import { useMemo } from 'react'

class SmsCodeStore {
  private timer: NodeJS.Timeout
  private _timeWaitSec = 0
  private _reaction: IReactionDisposer
  attempt = 0
  timeWaitSec = 0

  constructor(private context: AppContext) {
    makeAutoObservable(this, {
      init: action.bound,
      destroy: action.bound,
      startTimerDecrement: action.bound,
      stopTimerDecrement: action.bound,
    })
  }

  init(props: { attempt: number; timeWaitSec: number }) {
    this.attempt = props.attempt
    this._timeWaitSec = props.timeWaitSec

    this.startTimerDecrement()
    this._reaction = reaction(
      () => this.attempt,
      () => {
        if (this.attempt > 0) {
          this.startTimerDecrement()
        }
      }
    )
  }

  setTimeWaitSec(props: { timeWaitSec: number }) {
    this._timeWaitSec = props.timeWaitSec

    this.startTimerDecrement()
  }

  destroy() {
    this.stopTimerDecrement()
    this._reaction && this._reaction()
  }

  startTimerDecrement() {
    this.timeWaitSec = this._timeWaitSec
    this.stopTimerDecrement()
    this.timer = setInterval(() => {
      runInAction(() => {
        if (this.timeWaitSec === 1) {
          this.stopTimerDecrement()
        }
        this.timeWaitSec--
      })
    }, 1000)
  }

  stopTimerDecrement() {
    clearInterval(this.timer)
  }

  decrementAttempt() {
    this.attempt = this.attempt - 1
  }

  /**
   * @return 00:56 | 01:20:00
   */
  get timeWaitString() {
    const pad = (n: number) => (n < 10 ? `0${n}` : n)
    let minutes = Math.floor(this.timeWaitSec / 60)
    const secs = this.timeWaitSec % 60
    const hours = Math.floor(minutes / 60)
    minutes = minutes % 60
    const lastFragment = `${pad(minutes)}:${pad(secs)}`
    return hours !== 0 ? `${pad(hours)}:${lastFragment}` : lastFragment
  }

  get isOverTimerWait() {
    return this.timeWaitSec <= 0
  }

  get isOverAttempt() {
    return this.attempt <= 0
  }
}

export const useSMSCodeStore = () => {
  const context = useAppContext<AppContext>()

  const store = useMemo(() => new SmsCodeStore(context), [])

  return store
}
