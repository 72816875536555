import React from 'react'
import { Control, Controller, UseFormSetValue } from 'react-hook-form'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { Tab } from '@platform-ui/components/Tab'
import { Input } from '@platform-ui/components/Input'
import { InputCardNumber } from '@platform-ui/components/InputCardNumber'
import { TabsStyled } from './styled'
import { FormValues } from './useFormState'
import { CARD_NUMBER, CONTRACT_NUMBER } from './constants'

export type TabsProps = {
  formType: 'contractNumber' | 'cardNumber'
  inputDisabled: boolean
  control: Control<FormValues>
  setValue: UseFormSetValue<FormValues>
}

export const Tabs: React.FC<TabsProps> = ({ formType, inputDisabled, control, setValue }) => {
  return (
    <>
      <TabsStyled
        onChange={(event, newValue) => {
          setValue('type', newValue as 'contractNumber' | 'cardNumber')
        }}
        value={formType}
      >
        <Tab value={CONTRACT_NUMBER}>Номер договора</Tab>
        <Tab value={CARD_NUMBER}>Номер карты</Tab>
      </TabsStyled>

      {formType === CARD_NUMBER && (
        <Controller<FormValues, 'cardNumber'>
          name={CARD_NUMBER}
          control={control}
          render={({ field, fieldState }) => (
            <FieldControl isBlock error={fieldState.error?.message}>
              <InputCardNumber
                isBlock
                isDisabled={inputDisabled}
                onClear={() => {
                  setValue(field.name, '')
                }}
                status={fieldState.error?.message ? 'error' : 'default'}
                placeholder="Номер карты"
                {...field}
              />
            </FieldControl>
          )}
        />
      )}

      {formType === CONTRACT_NUMBER && (
        <Controller<FormValues, 'contractNumber'>
          name={CONTRACT_NUMBER}
          control={control}
          render={({ field, fieldState }) => (
            <FieldControl isBlock error={fieldState.error?.message}>
              <Input
                isBlock
                type="text"
                isDisabled={inputDisabled}
                onClear={() => {
                  setValue(field.name, '')
                }}
                status={fieldState.error?.message ? 'error' : 'default'}
                placeholder="Номер договора"
                {...field}
              />
            </FieldControl>
          )}
        />
      )}
    </>
  )
}
