import styled from '@emotion/styled'
import { Container } from '@platform-ui/components/Container'
import { Button } from '@platform-ui/components/Button'
import { Accordion } from '@platform-ui/components/Accordion'
import { Body16, Headline28 } from '@platform-ui/typography'
import { ReSendSmsCode } from '../ReSendSMSCode'

export const ContainerCustom = styled(Container)(() => ({
  paddingTop: '24px',
  paddingBottom: '24px',
}))

export const Wrapper = styled.div(() => ({
  maxWidth: 467,
  textAlign: 'center',
}))

export const Title = styled(Headline28)(() => ({
  marginBottom: 12,
}))

export const Desc = styled(Body16)(() => ({
  marginBottom: 16,
}))

export const Prompt = styled(Body16)(({ theme }) => ({
  color: theme.color['text/attention'],
}))

export const Message = styled(Accordion)(() => ({
  marginTop: 24,
}))

export const ErrorMessage = styled(Body16)(({ theme }) => ({
  color: theme.color['text/error'],
}))

export const ButtonSubmit = styled(Button)(() => ({
  marginTop: 128,
}))

export const ButtonBack = styled(Button)(() => ({
  marginTop: '12px',
}))

export const TextNoWrap = styled.span(() => ({
  whiteSpace: 'nowrap',
}))

export const ButtonReSendSms = styled(ReSendSmsCode)(({ theme }) => ({
  marginTop: '24px',

  [`${theme.media.md}`]: {
    marginTop: '32px',
  },
}))

export const ButtonsContainer = styled.div(({ isMobile }) => ({
  padding: isMobile ? '0px' : '0px 49px',
}))
