import styled from '@emotion/styled'
import { Container } from '@platform-ui/components/Container'
import { Button } from '@platform-ui/components/Button'
import { Headline16, Headline28 } from '@platform-ui/typography'
import { CheckboxButton } from '@platform-ui/components/CheckboxButton'
import { FieldControl } from '@platform-ui/components/FieldControl'

export const Title = styled(Headline28)(({ theme }) => ({
  marginTop: '8px',
  width: '100%',
  marginBottom: '24px',
}))

export const ButtonCustom = styled(Button)(({ theme }) => ({
  marginTop: 0,
}))

export const FormContainer = styled(Container)(({ theme }) => {
  return {
    [`${theme.media.md}`]: {
      minHeight: '570px',
    },
    [`${theme.media.lg}`]: {
      margin: '64px 0 20px',
    },
  }
})

export const Text = styled(Headline16)(() => {
  return {
    marginLeft: '16px',
    flexGrow: 1,
  }
})

export const CheckboxButtonCustom = styled(CheckboxButton)(({ theme }) => {
  return {
    marginTop: '12px',
  }
})

export const FieldControlCustom = styled(FieldControl)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginBottom: '16px',
  }
})

export const Form = styled.form(() => ({
  height: '100%',
}))
