import { create, test } from 'vest'
import { FormValues } from './useFormState'

export const validationSuite = create((data: FormValues) => {
  test('password', () => {
    const { password } = data

    if (!password) {
      return Promise.reject('Обязательное поле')
    }

    const hasNumber = password.match(/\d+/)
    const hasCapitalLetter = password.match(/[A-ZА-ЯЁ]+/g)

    if (password.length < 8 || !(hasNumber && hasCapitalLetter)) {
      return Promise.reject('Недопустимый пароль')
    }
  })
})
