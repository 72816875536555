import React, { useState } from 'react'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { Controller } from 'react-hook-form'
import { FieldControl } from '@platform-ui/components/FieldControl'
import { Input } from '@platform-ui/components/Input'
import { observer } from 'mobx-react-lite'
import { ShowIcon } from '@platform-ui/icons/ShowIcon'
import { HideIcon } from '@platform-ui/icons/HideIcon'
import {
  ContainerCustom,
  Form,
  Title,
  SubTitle,
  ButtonSubmit,
  ButtonStyled,
  IconButtonStyled,
  AvatarCustom,
  AvatarShimmer,
} from './styled'
import { useFormState } from './useFormState'
import { Error } from '../Error'

export const Password = observer(() => {
  const [showPassword, setShowPassword] = useState(false)
  const {
    form,
    handleValidSubmit,
    passwordValue,
    passwordMutation,
    setPassportScreen,
    setSignInScreen,
    secondaryButtonClick,
    hasAuthPasswordAccessRule,
    firstName,
    avatarImage,
  } = useFormState()

  const buttonText =
    passwordMutation.isError && passwordMutation.error.status === 500
      ? 'Попробовать снова'
      : 'Продолжить'

  if (
    passwordMutation.isError &&
    (passwordMutation.error.status === 400 || passwordMutation.error.status === 403)
  ) {
    return <Error statusCode={passwordMutation.error.status} buttonClick={setSignInScreen} />
  }

  return (
    <ContainerCustom flexSm>
      <Row flexSm>
        <Col displaySm="flex" justifyContentSm="center" alignItemsSm="center">
          <Form onSubmit={form.handleSubmit(handleValidSubmit)}>
            <div>
              {hasAuthPasswordAccessRule && (
                <>
                  {firstName ? (
                    <>
                      <AvatarCustom src={avatarImage} username={firstName} />
                      <SubTitle>Здравствуйте, {firstName}!</SubTitle>
                    </>
                  ) : (
                    <>
                      <AvatarShimmer />
                      <SubTitle>Здравствуйте!</SubTitle>
                    </>
                  )}
                </>
              )}
              <Title>
                Введите пароль <br />
                для входа
              </Title>
              <Controller
                name="password"
                control={form.control}
                render={({ field }) => (
                  <FieldControl isBlock>
                    <Input
                      placeholder="Пароль"
                      type={showPassword ? 'text' : 'password'}
                      isDisabled={passwordMutation.isLoading}
                      isBlock
                      rightSlot={
                        passwordValue ? (
                          <IconButtonStyled onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <ShowIcon /> : <HideIcon />}
                          </IconButtonStyled>
                        ) : null
                      }
                      {...field}
                    />
                  </FieldControl>
                )}
              />
              <ButtonStyled isFullWidth view="ghost" onClick={setPassportScreen}>
                Не помню пароль / Нет пароля
              </ButtonStyled>
            </div>

            <div>
              <ButtonSubmit
                isFullWidth
                type="submit"
                isLoading={passwordMutation.isLoading}
                isDisabled={!passwordValue}
              >
                {buttonText}
              </ButtonSubmit>

              <ButtonStyled
                isFullWidth
                view="secondary"
                isDisabled={passwordMutation.isLoading}
                onClick={secondaryButtonClick}
              >
                {hasAuthPasswordAccessRule ? 'Выйти из профиля' : 'В начало'}
              </ButtonStyled>
            </div>
          </Form>
        </Col>
      </Row>
    </ContainerCustom>
  )
})
