import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ResponseType } from '@/models/ResponseType'
import { SplitedCodeInputsProps } from '@platform-ui/components/SplitedCodeInputs'
import { useState } from 'react'
import { useSmsVerifyMutation, SmsVerifyModel } from '@/api/auth/smsVerify'
import { useSmsResendMutation } from '@/api/auth/smsResend'
import { authorizationStorage } from '@/storage/authorization'

export const useFormState = () => {
  const context = useAppContext<AppContext>()
  const { maskedPhoneNumber, privateKey } = authorizationStorage.getState()

  const [splitedCodeStatus, setSplitedCodeStatus] =
    useState<SplitedCodeInputsProps['status']>('default')

  const [codeValue, setCodeValue] = useState('')

  const handleChangeSplitedCode = (value: string) => {
    /**
     * При установке нового значения value,
     * в splited code необходимо сбрасывать статус,
     * так как ранее введенные значение могло быть с ошибкой и тогда
     * индикатор всегда будет красным
     */
    setSplitedCodeStatus('default')
    setCodeValue(value)
  }

  const smsVerifyMutation = useSmsVerifyMutation()
  const smsResendMutation = useSmsResendMutation()

  const handleSubmit = async () => {
    setSplitedCodeStatus('success')

    let smsVerifyResponse: SmsVerifyModel.Response

    try {
      /**
       * Если клиент не нажал на чужой компьютер,
       * то сгенерировать ключи
       */
      if (context.auth.isRememberMe) {
        await context.auth.generateCryptoKeys()
      }

      smsVerifyResponse = await smsVerifyMutation.mutateAsync({
        smsCode: codeValue,
        apiVersion: context.config.apiVersion,
        publicKey: context.auth.publicKey,
      })
    } catch (error) {
      const err: SmsVerifyModel.ErrorResponse = error

      if (err.status === 400) {
        setSplitedCodeStatus('error')
      }
      if (err.status === 441) {
        /**
         * Перенаправление на стартовую страницу
         */
        context.auth.setCurrentScreen(context.auth.screens.SignIn)
      }
      if (err.status >= 500) {
        setSplitedCodeStatus('error')
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }

      return
    }

    /**
     * Обработка серверной ошибки
     */
    if (smsVerifyResponse?.data?.type !== ResponseType.ResolveWithData) {
      context.toastify.error(
        smsVerifyResponse?.data?.systemErrors.map((item) => item.message).join(', ')
      )
      return
    }

    if (!smsVerifyResponse.data.isValid) {
      setSplitedCodeStatus('error')
      context.toastify.error('СМС-код введён неверно. Попробуйте ещё раз')
      return
    }

    const { firstName, currentLevel, expiresIn, sessionId } = smsVerifyResponse.data

    /**
     * Установка уровня пользователя
     * Обязательно при каждом получении clientLevel с сервера
     */
    context.auth.setClientLevel(currentLevel)
    context.auth.setAuthId(sessionId)
    context.auth.setExpireIn(expiresIn)

    authorizationStorage.setState((state) => ({
      ...state,
      firstName,
    }))

    if (context.auth.createPassword && context.auth.isRememberMe) {
      /**
       * Перенаправление на страницу создания пароля
       */
      context.auth.setCreatePassword(false)
      context.auth.setCurrentScreen(context.auth.screens.PasswordCreate)
    } else {
      /**
       * Авторизовать пользователя
       */
      context.auth.signIn()
    }
  }

  const handleFulfilledSplitedCode = handleSubmit

  const setSignInScreen = () => {
    /**
     * Если пользователь пришёл со страницы ввода паспорта и хочет уйти, то сбросить флаг,
     * чтобы не открылась страница создания пароля в новом сценарии
     */
    if (context.auth.createPassword) {
      context.auth.setCreatePassword(false)
    }

    /**
     * Перенаправление на стартовую страницу
     */
    context.auth.setCurrentScreen(context.auth.screens.SignIn)
  }

  const buttonDisabled =
    smsVerifyMutation.isSuccess &&
    smsVerifyMutation.data.data.type === ResponseType.ResolveWithData &&
    smsVerifyMutation.data.data.isValid

  return {
    maskedPhoneNumber: maskedPhoneNumber || '{Номер телефона}',
    smsVerifyMutation,
    codeValue,
    splitedCodeStatus,
    setSignInScreen,
    handleChangeSplitedCode,
    handleFulfilledSplitedCode,
    buttonDisabled,
    smsResendMutation,
  }
}
