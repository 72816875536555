import Image from '@/next/image'
import BankLogo from '../assets/bank-logo.svg'

export const HomeCreditBankLogo = (props) => {
  const { className } = props

  return (
    <div className={className}>
      <Image src={BankLogo} alt="Home Bank Logo" />
    </div>
  )
}
