import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { vestResolver } from '@hookform/resolvers/vest'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { PasswordSetModel, usePasswordSetMutation } from '@/api/auth/passwordSet'
import { ResponseType } from '@/models/ResponseType'
import { useEnhancedEffect, useEvent } from '@platform-ui/utils'
import { debounce } from 'debounce'
import { validationSuite } from './validationForm'

export type FormValues = Readonly<{
  password: string
}>

export const useFormState = () => {
  const [hasNumber, setHasNumber] = useState(false)
  const [hasDifferentCaseLetters, setHasDifferentCaserCaseLetters] = useState(false)
  const [isCorrectLength, setIsCorrectLength] = useState(false)

  const form = useForm<FormValues>({
    resolver: vestResolver(validationSuite),
    mode: 'onBlur',
  })

  const context = useAppContext<AppContext>()
  const passwordSetMutation = usePasswordSetMutation()

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    let passwordSetResponse: PasswordSetModel.Response
    try {
      passwordSetResponse = await passwordSetMutation.mutateAsync({
        password: formValues.password,
        apiVersion: context.config.apiVersion,
      })
    } catch (error) {
      const err: PasswordSetModel.ErrorResponse = error

      if (err.status === 441) {
        /**
         * Перенаправление на стартовую страницу
         */
        context.auth.setCurrentScreen(context.auth.screens.SignIn)
      }
      if (err.status >= 500) {
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }

      return
    }

    if (passwordSetResponse?.data?.type === ResponseType.ResolveWithData) {
      /**
       * Авторизовать пользователя
       */
      context.auth.signIn()
    }
  }

  const { watch, getValues } = form

  const handleTypeEvent = useEvent(() => {
    let [password] = getValues(['password'])

    /**
     * Проверяем длину пароля
     */
    if (!isCorrectLength && password.length >= 8) setIsCorrectLength(true)
    if (isCorrectLength && password.length < 8) setIsCorrectLength(false)

    /**
     * Проверяем строку на наличие цифры
     */
    const matchNumber = password.match(/\d+/)
    if (!hasNumber && matchNumber) setHasNumber(true)
    if (hasNumber && !matchNumber) setHasNumber(false)

    /**
     * Проверяем строку на наличие одной заглавной и одной строчной буквы
     */
    const hasCapitalLetter = password.match(/[A-ZА-ЯЁ]+/g)
    const hasLowercaseLetter = password.match(/[a-zа-яё]+/g)
    const hasRequiredCharacters = hasCapitalLetter && hasLowercaseLetter

    if (!hasDifferentCaseLetters && hasRequiredCharacters) {
      setHasDifferentCaserCaseLetters(true)
    }
    if (hasDifferentCaseLetters && !hasRequiredCharacters) {
      setHasDifferentCaserCaseLetters(false)
    }
  })

  useEnhancedEffect(() => {
    const typeEventDebounce = debounce(handleTypeEvent, 200)

    const subscription = watch(() => {
      typeEventDebounce()
    })

    return () => {
      typeEventDebounce.clear()
      subscription.unsubscribe()
    }
  }, [watch, handleTypeEvent])

  const secondaryButtonClick = () => {
    /**
     * Перенаправление на стартовую страницу
     */
    context.auth.setCurrentScreen(context.auth.screens.SignIn)
  }

  const setSignInScreen = () => {
    /**
     * Перенаправление на стартовую страницу
     */
    context.auth.setCurrentScreen(context.auth.screens.SignIn)
  }

  return {
    form,
    handleValidSubmit,
    hasNumber,
    hasDifferentCaseLetters,
    isCorrectLength,
    secondaryButtonClick,
    passwordSetMutation,
    setSignInScreen,
  }
}
