import React, { useLayoutEffect, useState } from 'react'
import styled from '@emotion/styled'
import Icon from '../../assets/bg.jpg'
import { Container } from '@platform-ui/components/Container'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import Logotype from '../../assets/logotype.min.svg'
import Image from '@/next/image'
import { isBrowser } from '@platform-ui/utils'
export type THelloLayoutProps = {}

const RowCustom = styled(Row)(() => ({
  flex: 1,
}))

const Background = styled.div(({ theme }) => ({
  position: 'fixed',
  width: '50vw',
  background: '#281e43',
  left: 0,
  top: 0,
  zIndex: -1,
  height: '100%',
  minHeight: '100vh',

  '::before': {
    content: "''",
    position: 'absolute',
    top: 0,
    right: '-88px',
    width: 'calc(100% + 88px)',
    height: '100%',
    minHeight: '100vh',
    background: `50% / cover #281e43 url(${Icon})`,
  },
}))

const LogoLink = styled.a(({ theme }) => ({
  marginTop: '93px',
  padding: '16px',
  marginLeft: `-16px`,
  display: 'inline-flex',
  alignSelf: 'flex-start',
}))

const Content = styled.div(({ theme }) => ({
  marginBottom: '142px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

const Headline32 = styled.h1(({ theme }) => ({
  ...theme.typography['headline/32'],

  color: theme.color['text/tertiary'],
}))

const List = styled.ul(({ theme }) => ({
  ...theme.typography['additional/16 list'],

  marginTop: '32px',
  color: theme.color['text/tertiary'],
  paddingLeft: '24px',
}))

const Item = styled.li(({ theme }) => ({
  color: theme.color['text/tertiary'],
  marginTop: '16px',
}))

const ColCustom = styled(Col)<{ isMacOs: boolean }>((isMacOs) => ({
  [`@media (min-width: 1200px) and (max-width: 1300px)`]: {
    marginLeft: isMacOs ? 64 : 60,
  },
}))

export const HelloLayout: React.FC<THelloLayoutProps> = ({ children }) => {
  const [isMacOs, setIsMacOs] = useState<boolean>(true)

  useLayoutEffect(() => {
    setIsMacOs(
      isBrowser &&
        new RegExp(/(macintosh|macintel|macppc|mac68k|macos)/i).test(globalThis.navigator.userAgent)
    )
  }, [])

  return (
    <Container flexSm>
      <RowCustom flexSm flexMd>
        <Col widthLg={12} hideSm hideMd displaySm="flex" flexDirectionSm={'column'}>
          <Background />
          <LogoLink href={'/'}>
            <Image src={Logotype} alt="Home Bank" />
          </LogoLink>
          <Content>
            <Headline32>Интернет-банк: просто открыть, легко использовать!</Headline32>
            <List>
              <Item>Оформляйте кредит, дебетовую или кредитную карту без визита офиса</Item>
              <Item>Вносите платежи с карты любого банка</Item>
              <Item>Зарабатывайте кешбэк с программой Польза</Item>
              <Item>Оплачивайте ЖКХ, мобильную связь и другие услуги онлайн</Item>
            </List>
          </Content>
        </Col>
        <ColCustom isMacOs={isMacOs} widthLg={10} offsetLg={2}>
          {children}
        </ColCustom>
      </RowCustom>
    </Container>
  )
}
