import { AdapterModel } from './AdapterModel'
import { ResponseType } from '@/models/ResponseType'
import { RejectedWithSystemErrorResponse } from '@/models/RejectedWithSystemErrorResponse'
import { HttpClient } from '@/core'

export const adapter: AdapterModel.Func = (data) => {
  const errorData = data as AdapterModel.ResponseDataError
  /**
   * Сервер всегда возвращает status=200 в заголовке
   * Реальный статус приходит в теле ответа
   */
  if (errorData?.StatusCode >= 400) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: errorData.ErrorDetails
        ? errorData.ErrorDetails.map(({ Error }) => ({
            type: Error,
            message: Error,
          }))
        : [],
    }
    throw HttpClient.createError(errorData?.StatusCode, exception)
  }
  try {
    const successDataValid = data as AdapterModel.ResponseDataSuccessValid
    const successDataInValid = data as AdapterModel.ResponseDataSuccessInValid

    if (!successDataInValid.Result.IsValid) {
      return {
        type: ResponseType.ResolveWithData,
        isValid: successDataInValid.Result.IsValid,
      }
    }

    return {
      type: ResponseType.ResolveWithData,
      isValid: successDataValid.Result.IsValid,
      expiresIn: successDataValid.Result.ExpiresIn * 1000, // sec => ms
      firstName: successDataValid.Result.FirstName,
      clientType: successDataValid.Result.ClientType,
      currentLevel: successDataValid.Result.CurrentLevel,
      sessionId: successDataValid.Result.SessionId,
    }
  } catch (error) {
    const exception: RejectedWithSystemErrorResponse = {
      type: ResponseType.RejectedWithSystemError,
      systemErrors: [{ message: 'Ошибка при обработке полученных данных', type: 'ADAPTER_ERROR' }],
    }

    throw HttpClient.createError(500, exception)
  }
}
