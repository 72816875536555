import React from 'react'
import { Row } from '@platform-ui/components/Row'
import { Col } from '@platform-ui/components/Col'
import { observer } from 'mobx-react-lite'
import { useFormState } from './useFormState'
import {
  ContainerCustom,
  Wrapper,
  Title,
  Desc,
  Message,
  ErrorMessage,
  ButtonSubmit,
  ButtonBack,
  TextNoWrap,
  ButtonReSendSms,
  Prompt,
  ButtonsContainer,
} from './styled'
import { Body16 } from '@platform-ui/typography'
import { SplitedCodeInputs } from '@platform-ui/components/SplitedCodeInputs'
import { Error } from '../Error'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'

export type TSMSCodeProps = {}

export const SMSCode = observer<TSMSCodeProps>((props) => {
  const {
    maskedPhoneNumber,
    smsVerifyMutation,
    codeValue,
    splitedCodeStatus,
    setSignInScreen,
    handleChangeSplitedCode,
    handleFulfilledSplitedCode,
    buttonDisabled,
    smsResendMutation,
  } = useFormState()
  const context = useAppContext<AppContext>()
  const isMobile = context.config.device.isMobile
  const buttonText =
    smsVerifyMutation.isError && smsVerifyMutation.error.status === 500
      ? 'Попробовать снова'
      : 'Продолжить'

  if (smsVerifyMutation.isError && smsVerifyMutation.error.status === 403) {
    return <Error statusCode={smsVerifyMutation.error.status} buttonClick={setSignInScreen} />
  }
  if (smsResendMutation.isError && smsResendMutation.error.status === 403) {
    return <Error statusCode={smsResendMutation.error.status} buttonClick={setSignInScreen} />
  }

  return (
    <ContainerCustom flexSm>
      <Row flexSm>
        <Col displaySm="flex" justifyContentSm="center" alignItemsSm="center">
          <Wrapper>
            <Title>Добро пожаловать!</Title>
            {context.auth.createPassword && (
              <Prompt>
                При продолжении, существующий пароль&nbsp;будет&nbsp;сброшен.
                <br />
                Новый пароль можно будет создать на&nbsp;следующем экране.
              </Prompt>
            )}
            <Desc>Введите СМС-код</Desc>

            <SplitedCodeInputs
              length={4}
              value={codeValue}
              onChange={handleChangeSplitedCode}
              onFullfilled={handleFulfilledSplitedCode}
              isAutoFocus
              isReadOnly={smsVerifyMutation.isLoading || splitedCodeStatus === 'success'}
              status={splitedCodeStatus}
            />

            <Message isOpen>
              {splitedCodeStatus === 'error' ? (
                <ErrorMessage>Неправильный код</ErrorMessage>
              ) : (
                <Body16>
                  Мы выслали код подтверждения на номер <TextNoWrap>{maskedPhoneNumber}</TextNoWrap>
                </Body16>
              )}
            </Message>

            <ButtonReSendSms smsResendMutation={smsResendMutation} />

            <ButtonsContainer isMobile={isMobile}>
              <ButtonSubmit
                isFullWidth
                isDisabled={!buttonDisabled}
                isLoading={smsVerifyMutation.isLoading}
              >
                {buttonText}
              </ButtonSubmit>
              <ButtonBack isFullWidth view="secondary" onClick={setSignInScreen}>
                В начало
              </ButtonBack>
            </ButtonsContainer>
          </Wrapper>
        </Col>
      </Row>
    </ContainerCustom>
  )
})
