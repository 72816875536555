import { useMutation, MutateOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { SmsVerifyModel } from './SmsVerifyModel'

export const smsVerify: SmsVerifyModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.auth.smsVerify,
    {} as ApiManagerModel.ApiOption
  )

  const otherProps = props.publicKey
    ? {
        publicKey: props.publicKey,
      }
    : {}

  return await HttpClient.post<
    SmsVerifyModel.ResponseData,
    void,
    SmsVerifyModel.RequestData,
    SmsVerifyModel.RequestHeaders,
    SmsVerifyModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      sms: props.smsCode,
      ...otherProps,
    },
    responseAdapter: adapter,
    control,
  })
}

export function useSmsVerifyMutation(
  options?: MutateOptions<
    SmsVerifyModel.Response,
    SmsVerifyModel.ErrorResponse,
    SmsVerifyModel.Props
  >
) {
  return useMutation(smsVerify, options)
}
