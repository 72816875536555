import styled from '@emotion/styled'
import { Button } from '@platform-ui/components/Button'

export const ButtonReSendSms = styled(Button)(({ theme }) => ({
  margin: '24px auto 0',
  maxWidth: 303,

  [`${theme.media.md}`]: {
    margin: '32px auto 0',
  },
}))

export const Message = styled.div(({ theme }) => ({
  background: theme.color['surface/tertiary'],
  borderRadius: '12px',
  width: '100%',
  padding: '16px',
  textAlign: 'center',
}))
