import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { vestResolver } from '@hookform/resolvers/vest'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { FormValues } from './models'
import { validationSuite } from './validationForm'
import { authorizationStorage } from '@/storage/authorization'

export const useFormState = () => {
  const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false)

  const context = useAppContext<AppContext>()

  const form = useForm<FormValues>({
    defaultValues: {
      birthday: '',
      phoneNumber: '+7',
    },
    resolver: vestResolver(validationSuite),
    mode: 'onBlur',
  })

  useEffect(() => {
    /**
     * Удаление состояния проверки
     */
    return validationSuite.reset
  }, [])

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    /**
     * Начало загрузки
     */
    setIsLoadingSubmitForm(true)

    /**
     * Сохранение пожелания пользователя оставаться в системе
     */
    context.auth.setRememberMe(!formValues.disableRememberMe)

    authorizationStorage.setState((state) => ({
      ...state,
      birthday: formValues.birthday,
      maskedPhoneNumber: formValues.phoneNumber,
    }))

    /**
     * Конец загрузки
     */
    setIsLoadingSubmitForm(false)

    /**
     * Перенаправление на ввод пароля
     */
    context.auth.setCurrentScreen(context.auth.screens.Password)
  }

  return {
    handleValidSubmit,
    form,
    isLoadingSubmitForm,
  }
}
