import styled from '@emotion/styled'
import { Container } from '@platform-ui/components/Container'
import { Button } from '@platform-ui/components/Button'
import { Headline28, Body14, Body16 } from '@platform-ui/typography'
import { IconButton } from '@platform-ui/components/IconButton'

export const ContainerCustom = styled(Container)(() => ({
  paddingTop: '24px',
  paddingBottom: '24px',
}))

export const Form = styled.form(({ theme }) => ({
  maxWidth: 368,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const Title = styled(Headline28)(() => ({
  textAlign: 'center',
}))

export const Desc = styled(Body16)(() => ({
  marginTop: 16,
  marginBottom: 24,
  textAlign: 'center',
}))

export const HintWrapper = styled.div(() => ({
  display: 'flex',
  marginTop: 12,
  alignItems: 'center',
}))

export const HintText = styled(Body14)<{ isError: boolean; isSuccess: boolean }>(
  ({ isError, isSuccess, theme }) => {
    return {
      marginLeft: 4,
      color:
        (isSuccess && theme.color['text/success']) ||
        (isError && theme.color['text/validationError']) ||
        theme.color['icons/secondary'],
    }
  }
)

export const Dot = styled.div<{ isError: boolean }>(({ isError, theme }) => ({
  width: 24,
  height: 24,
  position: 'relative',

  ':after': {
    content: "''",
    display: 'block',
    position: 'absolute',
    backgroundColor: isError ? theme.color['text/validationError'] : theme.color['icons/secondary'],
    width: 4,
    height: 4,
    borderRadius: '50%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
  },
}))

export const ButtonSubmit = styled(Button)(() => ({
  marginTop: 128,
}))

export const ButtonStyled = styled(Button)(() => ({
  marginTop: '12px',
}))

export const IconButtonStyled = styled(IconButton)(() => ({
  padding: 0,
}))
