import { SubmitHandler, useForm } from 'react-hook-form'
import { PasswordModel, usePasswordMutation } from '@/api/auth/password'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { toCorrectBirthDate, toCurrentFormatPhone } from '@/utils'
import { authorizationStorage } from '@/storage/authorization'
import { Rules } from '@/models/Rules'
import { ResponseType } from '@/models/ResponseType'
import { useRefreshMutation, RefreshModel } from '@/api/auth/refresh'
import { dateToTicks, encodeData, sign, importKey, ALGORITHM_TYPE } from '@/utils'

export type FormValues = Readonly<{
  password: string
}>

export const useFormState = () => {
  const form = useForm<FormValues>({
    mode: 'onBlur',
  })

  const { maskedPhoneNumber, birthday, firstName, avatarImage, privateKey } =
    authorizationStorage.getState()

  const context = useAppContext<AppContext>()

  const passwordMutation = usePasswordMutation()
  const refreshMutation = useRefreshMutation()

  const hasAuthPasswordAccessRule = context.auth.rules.has(Rules.AuthPasswordAccess)

  const handleValidSubmit: SubmitHandler<FormValues> = async (formValues) => {
    /**
     * Если пользователь был авторизован, то войти по ключу
     */
    if (hasAuthPasswordAccessRule) {
      /**
       * Для входа по ключу требуется firstName.
       */
      if (!firstName || !privateKey) {
        await context.auth.signOut()
        await context.toastify.error('Просьба пройти повторную авторизацию')
        return
      }

      const ticks = dateToTicks()
      const MESSAGE = `${firstName.toLowerCase()}|${ticks}`

      // достаём приватный ключ
      importKey(JSON.parse(atob(privateKey))).then((key) => {
        // Кодируем сообщение в нужный формат.
        const encodedMessage = encodeData(MESSAGE)

        // Создаем подпись
        sign(ALGORITHM_TYPE, key, encodedMessage).then((signedData) => {
          // Конвертируем подпись в строку.
          const exportedAsBase64 = btoa(String.fromCharCode(...new Uint8Array(signedData)))

          refreshMutation
            .mutateAsync({
              password: formValues.password,
              signedData: exportedAsBase64,
              signedDataCreateTime: ticks,
              apiVersion: context.config.apiVersion,
            })
            .then((resp) => {
              if (resp.data.type === ResponseType.ResolveWithData) {
                context.auth.setExpireIn(resp.data.expiresIn)
                context.auth.updateSignedExpireAt()
                context.auth.signIn()
              }
            })
            .catch((error) => {
              const err: RefreshModel.ErrorResponse = error
              if (err.status >= 400) {
                context.auth.signOut()
                context.toastify.error('Просьба пройти повторную авторизацию')
                return
              }
              if (err.status >= 500) {
                context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
              }

              return
            })
        })
      })
      return
    }

    let passwordResponse: PasswordModel.Response

    const trimPhone =
      maskedPhoneNumber.length > 10
        ? toCurrentFormatPhone(maskedPhoneNumber).slice(1)
        : maskedPhoneNumber

    try {
      passwordResponse = await passwordMutation.mutateAsync({
        clientId: context.auth.clientId,
        birthDate: toCorrectBirthDate(birthday),
        password: formValues.password,
        phone: trimPhone,
        deviceName: context.config.device.platform,
        apiVersion: context.config.apiVersion,
      })
    } catch (error) {
      const err: PasswordModel.ErrorResponse = error

      if (err.status === 441) {
        /**
         * Перенаправление на стартовую страницу
         */
        context.auth.setCurrentScreen(context.auth.screens.SignIn)
      }

      if (err.status >= 500) {
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }

      return
    }

    /**
     * Обработка серверной ошибки
     */

    if (passwordResponse?.data?.type !== ResponseType.ResolveWithData) {
      context.toastify.error(
        passwordResponse?.data?.systemErrors.map((item) => item.message).join(', ')
      )
      return
    }

    /**
     * Перенаправление на подтверждение кода (кейс регистрации)
     */
    context.auth.setCurrentScreen(context.auth.screens.SMSCode)
  }

  const setPassportScreen = () => {
    /**
     * Перенаправление на страницу ввода паспортных данных
     */
    context.auth.setCurrentScreen(context.auth.screens.Passport)
  }

  const setSignInScreen = () => {
    /**
     * Перенаправление на стартовую страницу
     */
    context.auth.setCurrentScreen(context.auth.screens.SignIn)
  }

  const secondaryButtonClick = () => {
    if (hasAuthPasswordAccessRule) {
      /**
       * Полностью выходим из профиля
       */
      context.auth.signOut()
    } else {
      /**
       * Перенаправление на стартовую страницу
       */
      context.auth.setCurrentScreen(context.auth.screens.SignIn)
    }
  }

  const { watch } = form

  const passwordValue = watch('password')

  return {
    form,
    handleValidSubmit,
    passwordValue,
    passwordMutation,
    setPassportScreen,
    setSignInScreen,
    hasAuthPasswordAccessRule,
    secondaryButtonClick,
    firstName,
    avatarImage,
  }
}
