import { useMutation, MutateOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { SmsResendModel } from './SmsResendModel'

export const smsResend: SmsResendModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.auth.smsResend,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.post<
    SmsResendModel.ResponseData,
    void,
    SmsResendModel.RequestData,
    SmsResendModel.RequestHeaders,
    SmsResendModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: null,
    responseAdapter: adapter,
    control,
  })
}

export function useSmsResendMutation(
  options?: MutateOptions<SmsResendModel.Response, SmsResendModel.ErrorResponse, any>
) {
  return useMutation(smsResend, options)
}
