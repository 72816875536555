import { SmsResendModel } from '@/api/auth/smsResend'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { ResponseType } from '@/models/ResponseType'
import { useSMSCodeStore } from './useSMSCodeStore'

export const useFormState = (smsResendMutation) => {
  const context = useAppContext<AppContext>()

  const timerStore = useSMSCodeStore()

  const handleSubmit = async () => {
    /**
     * Уменьшение кол-ва попыток отправки кода
     */
    timerStore.decrementAttempt()

    let resendSmsResponse: SmsResendModel.Response
    try {
      resendSmsResponse = await smsResendMutation.mutateAsync({
        apiVersion: context.config.apiVersion,
      })
    } catch (error) {
      /**
       * Обработка сетевой ошибки
       */
      const err: SmsResendModel.ErrorResponse = error

      if (err.status === 441) {
        /**
         * Перенаправление на стартовую страницу
         */
        context.auth.setCurrentScreen(context.auth.screens.SignIn)
      }
      if (err.status >= 500) {
        context.toastify.error(err.data.systemErrors.map((item) => item.message).join(', '))
      }

      return
    }

    /**
     * Обработка серверной ошибки
     */
    if (resendSmsResponse?.data?.type !== ResponseType.ResolveWithData) {
      context.toastify.error(
        resendSmsResponse?.data?.systemErrors.map((item) => item.message).join(', ')
      )
      return
    }

    if (resendSmsResponse.data?.resendIn) {
      timerStore.setTimeWaitSec({
        timeWaitSec: resendSmsResponse.data.resendIn,
      })
    }
  }

  const handleClick = () => {
    if (timerStore.isOverTimerWait) {
      handleSubmit()
    }
  }

  return {
    handleClick,
    smsResendMutation,
    timerStore,
  }
}
