import styled from '@emotion/styled'
import { Container } from '@platform-ui/components/Container'
import { Button } from '@platform-ui/components/Button'
import { Body16, Headline28 } from '@platform-ui/typography'

export const ContainerCustom = styled(Container)(({ theme }) => ({
  paddingTop: '56px',
  paddingBottom: '24px',

  [`${theme.media.md}`]: {
    paddingTop: '24px',
  },
}))

export const Wrapper = styled.div(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  maxWidth: 368,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const TitleStyled = styled(Headline28)(() => ({
  maxWidth: 336,
  margin: '12px auto',
}))

export const DescStyled = styled(Body16)(() => ({
  maxWidth: 315,
  margin: '0 auto',
}))

export const EmojiStyled = styled.span(() => ({
  fontSize: 28,
  display: 'block',
  marginTop: 5,
}))

export const ButtonStyled = styled(Button)(() => ({
  marginTop: 128,
}))
