import styled from '@emotion/styled'
import { Container } from '@platform-ui/components/Container'
import { Row } from '@platform-ui/components/Row'
import { HomeCreditBankLogo } from '../../components/Logo'

export const ContainerCustom = styled(Container)(() => ({
  paddingTop: '24px',
  paddingBottom: '24px',
}))

export const RowCustom = styled(Row)(({ theme }) => ({
  [`${theme.media.md}`]: {
    alignItems: 'center',
  },
}))

export const HomeCreditBankLogoStyled = styled(HomeCreditBankLogo)(({ theme }) => ({
  paddingTop: '40px',
  marginBottom: '24px',

  [`${theme.media.md}`]: {
    paddingTop: '72px',
    marginBottom: '40px',
  },
  [`${theme.media.lg}`]: {
    display: 'none',
  },
}))
