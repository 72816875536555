import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useFormState } from './useFormState'
import { ButtonReSendSms, Message } from './styled'

export type ReSendSmsCodeProps = {
  className?: string
  smsResendMutation: ReturnType<typeof useFormState>['smsResendMutation']
}

export const ReSendSmsCode = observer<ReSendSmsCodeProps>((props) => {
  const { handleClick, smsResendMutation, timerStore } = useFormState(props.smsResendMutation)

  useEffect(() => {
    timerStore.init({
      attempt: 3,
      timeWaitSec: 60,
    })
    return () => {
      timerStore.destroy()
    }
  }, [])

  if (timerStore.isOverAttempt) {
    // TODO: Подумать на текстом ошибки при отсутствии кол-ва попыток
    return (
      <Message {...props}>
        Попытки отправки кода закончились. Попробуйте вернуться в начало и повторить запрос кода.
      </Message>
    )
  }

  return (
    <ButtonReSendSms
      {...props}
      isFullWidth
      size="m"
      isLoading={smsResendMutation.isLoading}
      view="secondary"
      onClick={handleClick}
    >
      {timerStore.isOverTimerWait
        ? 'Повторно запросить код'
        : `Повторно запросить код через ${timerStore.timeWaitString}`}
    </ButtonReSendSms>
  )
})
