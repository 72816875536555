import { useMutation, MutateOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { RefreshModel } from './RefreshModel'

export const refresh: RefreshModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.auth.refresh,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.post<
    RefreshModel.ResponseData,
    void,
    RefreshModel.RequestData,
    RefreshModel.RequestHeaders,
    RefreshModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      Password: props.password,
      SignedData: props.signedData,
      SignedDataCreateTime: props.signedDataCreateTime,
    },
    responseAdapter: adapter,
    control,
  })
}

export function useRefreshMutation(
  options?: MutateOptions<RefreshModel.Response, RefreshModel.ErrorResponse, RefreshModel.Props>
) {
  return useMutation(refresh, options)
}
