import React from 'react'
import { SMSCode } from './containers/SMSCode'
import { SignIn } from './containers/SignIn'
import { Passport } from './containers/Passport'
import { observer } from 'mobx-react-lite'
import { useAppContext } from '@/core'
import { AppContext } from '@/models/AppContext'
import { Password } from './containers/Password'
import { PasswordCreate } from './containers/PasswordCreate'
import { AlertBanner } from '@/components/AlertBanner'
import { AlertBannerWrapper } from './styled'

export const SwitchContainers = observer(() => {
  const { auth } = useAppContext<AppContext>()

  const byType = {
    [auth.screens.Password]: Password,
    [auth.screens.Passport]: Passport,
    [auth.screens.SMSCode]: SMSCode,
    [auth.screens.PasswordCreate]: PasswordCreate,
  }

  const Render = byType[auth.currentScreen] || SignIn

  return <Render />
})

export const AlertBannerComponent = observer(() => {
  const { auth } = useAppContext<AppContext>()

  if (auth.currentScreen !== auth.screens.SignIn) return null

  return (
    <AlertBannerWrapper>
      <AlertBanner />
    </AlertBannerWrapper>
  )
})

export const Authorization: React.FC = observer(() => (
  <>
    <AlertBannerComponent />
    <SwitchContainers />
  </>
))
