import styled from '@emotion/styled'
import { Container } from '@platform-ui/components/Container'
import { Button } from '@platform-ui/components/Button'
import { Headline28, Body16 } from '@platform-ui/typography'
import { IconButton } from '@platform-ui/components/IconButton'
import { Avatar } from '@/components/Avatar'
import { Shimmer } from '@platform-ui/components/Shimmer'

export const ContainerCustom = styled(Container)(({ theme }) => ({
  paddingTop: '56px',
  paddingBottom: '24px',

  [`${theme.media.md}`]: {
    paddingTop: '24px',
  },
}))

export const Form = styled.form(({ theme }) => ({
  maxWidth: 368,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [`${theme.media.md}`]: {
    height: 'auto',
  },
}))

export const Title = styled(Headline28)(() => ({
  textAlign: 'center',
  marginBottom: '24px',
}))

export const SubTitle = styled(Body16)(() => ({
  textAlign: 'center',
  marginBottom: 40,
  marginTop: 16,
}))

export const ButtonSubmit = styled(Button)(() => ({
  marginTop: 128,
}))

export const ButtonStyled = styled(Button)(() => ({
  marginTop: '12px',
}))

export const IconButtonStyled = styled(IconButton)(() => ({
  padding: 0,
}))

export const AvatarCustom = styled(Avatar)(({ theme }) => ({
  width: '60px',
  height: '60px',
  margin: '0 auto',

  [`${theme.media.md}`]: {
    width: '80px',
    height: '80px',
  },
}))

export const AvatarShimmer = styled(Shimmer)(({ theme }) => ({
  height: '60px',
  width: '60px',
  borderRadius: '50%',
  margin: '0 auto',

  [`${theme.media.md}`]: {
    height: '80px',
    width: '80px',
  },
}))
