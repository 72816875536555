import { useMutation, MutateOptions } from 'react-query'
import { ApiManager, ApiManagerModel, HttpClient } from '@/core'
import { ApiState } from '@/models/ApiState'
import { adapter } from './adapters/adapter'
import { PasswordModel } from './PasswordModel'

export const password: PasswordModel.Func = async (props) => {
  const { path, control } = ApiManager.getState<ApiState, ApiManagerModel.ApiOption>(
    (state) => state.auth.password,
    {} as ApiManagerModel.ApiOption
  )

  return await HttpClient.post<
    PasswordModel.ResponseData,
    void,
    PasswordModel.RequestData,
    PasswordModel.RequestHeaders,
    PasswordModel.ErrorResponse
  >({
    url: path,
    headers: {
      _ver_: props.apiVersion,
      _os_: '4',
    },
    data: {
      dfp: props.clientId,
      birth: props.birthDate,
      password: props.password,
      phone: props.phone,
      osType: 4, // always 4 for web
      deviceName: props.deviceName,
    },
    responseAdapter: adapter,
    control,
  })
}

export function usePasswordMutation(
  options?: MutateOptions<PasswordModel.Response, PasswordModel.ErrorResponse, PasswordModel.Props>
) {
  return useMutation(password, options)
}
